<template>
    <app-layout>
        <template #header>
            <div class="flex flex-row">
                <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-8">
                    SafetyPulse
                </h2>

                <a href="#add-safety-pulse" class="font-semibold text-xl text-gray-400 mr-8">
                    Send
                </a>

                <a href="#list-notifications" class="font-semibold text-xl text-gray-400 mr-8">
                    Notified users
                </a>

            </div>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">

                <safety-pulse-form class="mt-10 sm:mt-0" />
            </div>
        </div>
    </app-layout>
</template>

<script>

    import AppLayout from '@/Layouts/AppLayout'
    import JetSectionTitle from '@/Jetstream/SectionTitle'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton'
    import JetButton from '@/Jetstream/Button'
    import moment from "moment";
    import SafetyPulseForm from "@/Pages/SafetyPulse/SafetyPulseForm.vue";

    export default {
        props: [
            'notifications'
        ],

        components: {
            SafetyPulseForm,
            AppLayout,
            JetSectionTitle,
            JetSecondaryButton,
            JetButton
        },

        methods: {
        }
    }
</script>
