<template>
    <div class="min-h-screen md:grid md:grid-cols-2 flex flex-col gap-0">
        <div class="flex flex-col sm:justify-center items-center py-12 bg-lil-pulse">
            <div>
                <slot name="app-logo" />
            </div>
        </div>
        <div class="flex flex-col flex-grow sm:justify-center items-center py-12 bg-gray-200">
            <div>
                <slot name="logo" />

                <div class="text-lg justify-center text-gray-600 font-bold text-center">
                    Administration
                </div>

            </div>

            <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
                <slot />
            </div>
        </div>
    </div>
</template>
