import { render } from "./Checkbox.vue?vue&type=template&id=5ec72712"
import script from "./Checkbox.vue?vue&type=script&lang=js"
export * from "./Checkbox.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5ec72712"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5ec72712', script)) {
    api.reload('5ec72712', script)
  }
  
  module.hot.accept("./Checkbox.vue?vue&type=template&id=5ec72712", () => {
    api.rerender('5ec72712', render)
  })

}

script.__file = "resources/js/Jetstream/Checkbox.vue"

export default script