<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="209.336" height="193.364" viewBox="0 0 209.336 193.364">
        <g id="Groupe_874" data-name="Groupe 874" transform="translate(-333.68 -52.161)">
            <path id="Tracé_393" data-name="Tracé 393" d="M408.52,153.778l-.146-46.045v-1.244l-48.347,50.58V53.609l-10,7.059V181.329l48.348-50.451.147,30.668v16.492l48.348-50.452V179.3h10V103.2Z" transform="translate(85.399 0)" fill="#141c3f" stroke="#141d3e" stroke-miterlimit="10" stroke-width="1.5"/>
            <path id="Tracé_394" data-name="Tracé 394" d="M346.817,64.3v50h15.752v11.126H333.68V74.9Z" transform="translate(0 55.899)" fill="#141d3e"/>
            <path id="Tracé_395" data-name="Tracé 395" d="M352.544,60.336v85.837H339.407V70.926Z" transform="translate(29.929 35.157)" fill="#141d3e"/>
            <path id="Tracé_396" data-name="Tracé 396" d="M356.355,57.785v90.594h15.758V159.5H343.218V68.375Z" transform="translate(49.845 21.826)" fill="#141d3e"/>
            <path id="Tracé_397" data-name="Tracé 397" d="M346.817,126.626H333.68V76.09h20.913q8.508,0,13.031,4.42t4.532,12.47q0,8.041-4.532,12.464t-13.031,4.427h-7.776Zm0-27.344h4.358q7.238,0,7.241-6.3t-7.241-6.307h-4.358Z" transform="translate(0 117.486)" fill="#141d3e"/>
            <path id="Tracé_398" data-name="Tracé 398" d="M354.115,76.09v27.481c0,1.469.056,2.982.168,4.526a12.353,12.353,0,0,0,1.009,4.184,6.879,6.879,0,0,0,2.646,3.051,9.315,9.315,0,0,0,5.093,1.171,9.143,9.143,0,0,0,5.062-1.171,7.2,7.2,0,0,0,2.646-3.051,11.955,11.955,0,0,0,1.04-4.184c.112-1.544.168-3.057.168-4.526V76.09h13.068v29.287q0,11.8-5.392,17.233t-16.592,5.429q-11.2,0-16.623-5.429t-5.429-17.233V76.09Z" transform="translate(38.144 117.486)" fill="#141d3e"/>
            <path id="Tracé_399" data-name="Tracé 399" d="M362.89,76.09V115.5h15.751v11.126H349.753V76.09Z" transform="translate(83.996 117.486)" fill="#141d3e"/>
            <path id="Tracé_400" data-name="Tracé 400" d="M385.277,90.208a16.458,16.458,0,0,0-4.29-2.578,11.393,11.393,0,0,0-4.159-.84A6.7,6.7,0,0,0,372.676,88a3.74,3.74,0,0,0-1.613,3.144,3.125,3.125,0,0,0,.8,2.21,6.943,6.943,0,0,0,2.117,1.513,17.752,17.752,0,0,0,2.945,1.1c1.1.318,2.179.648,3.25,1.009q6.435,2.139,9.42,5.728a14.168,14.168,0,0,1,2.982,9.351,18.217,18.217,0,0,1-1.307,7.042,14.668,14.668,0,0,1-3.817,5.392,17.575,17.575,0,0,1-6.17,3.487,25.83,25.83,0,0,1-8.28,1.245,30.462,30.462,0,0,1-17.763-5.7l5.634-10.584a23.072,23.072,0,0,0,5.834,3.885,14.019,14.019,0,0,0,5.7,1.27,6.755,6.755,0,0,0,4.788-1.476,4.572,4.572,0,0,0,1.177-5.323,4.238,4.238,0,0,0-1.345-1.544,10.563,10.563,0,0,0-2.447-1.307c-1-.4-2.223-.853-3.648-1.345-1.7-.535-3.368-1.127-4.993-1.774a15.7,15.7,0,0,1-4.358-2.578,11.907,11.907,0,0,1-3.082-4.059,14.271,14.271,0,0,1-1.177-6.2,17.77,17.77,0,0,1,1.245-6.8,14.955,14.955,0,0,1,3.48-5.23,15.521,15.521,0,0,1,5.5-3.381,20.646,20.646,0,0,1,7.272-1.214,31.934,31.934,0,0,1,7.845,1.04A34.59,34.59,0,0,1,390.5,79.96Z" transform="translate(112.687 116.3)" fill="#141d3e"/>
            <path id="Tracé_401" data-name="Tracé 401" d="M391.322,87.216H375.7v8.449H390.45V106.79H375.7v8.71h15.621v11.126H362.564V76.09h28.758Z" transform="translate(150.946 117.486)" fill="#141d3e"/>
        </g>
    </svg>
</template>
