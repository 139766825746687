import { render } from "./Show.vue?vue&type=template&id=4a0b3982"
import script from "./Show.vue?vue&type=script&lang=js"
export * from "./Show.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4a0b3982"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4a0b3982', script)) {
    api.reload('4a0b3982', script)
  }
  
  module.hot.accept("./Show.vue?vue&type=template&id=4a0b3982", () => {
    api.rerender('4a0b3982', render)
  })

}

script.__file = "resources/js/Pages/Teams/Show.vue"

export default script