import { render } from "./TeamMemberManager.vue?vue&type=template&id=506cd804"
import script from "./TeamMemberManager.vue?vue&type=script&lang=js"
export * from "./TeamMemberManager.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "506cd804"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('506cd804', script)) {
    api.reload('506cd804', script)
  }
  
  module.hot.accept("./TeamMemberManager.vue?vue&type=template&id=506cd804", () => {
    api.rerender('506cd804', render)
  })

}

script.__file = "resources/js/Pages/Teams/TeamMemberManager.vue"

export default script