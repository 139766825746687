import { render } from "./Index.vue?vue&type=template&id=5b4a0131"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5b4a0131"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5b4a0131', script)) {
    api.reload('5b4a0131', script)
  }
  
  module.hot.accept("./Index.vue?vue&type=template&id=5b4a0131", () => {
    api.rerender('5b4a0131', render)
  })

}

script.__file = "resources/js/Pages/SafetyPulse/Index.vue"

export default script