import { render } from "./UpdateCompanyForm.vue?vue&type=template&id=576a882a"
import script from "./UpdateCompanyForm.vue?vue&type=script&lang=js"
export * from "./UpdateCompanyForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "576a882a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('576a882a', script)) {
    api.reload('576a882a', script)
  }
  
  module.hot.accept("./UpdateCompanyForm.vue?vue&type=template&id=576a882a", () => {
    api.rerender('576a882a', render)
  })

}

script.__file = "resources/js/Pages/Companies/UpdateCompanyForm.vue"

export default script