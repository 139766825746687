import { render } from "./InputError.vue?vue&type=template&id=045826dc"
import script from "./InputError.vue?vue&type=script&lang=js"
export * from "./InputError.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "045826dc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('045826dc', script)) {
    api.reload('045826dc', script)
  }
  
  module.hot.accept("./InputError.vue?vue&type=template&id=045826dc", () => {
    api.rerender('045826dc', render)
  })

}

script.__file = "resources/js/Jetstream/InputError.vue"

export default script