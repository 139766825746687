import { render } from "./ApplicationMark.vue?vue&type=template&id=6ed2e539"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6ed2e539"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6ed2e539', script)) {
    api.reload('6ed2e539', script)
  }
  
  module.hot.accept("./ApplicationMark.vue?vue&type=template&id=6ed2e539", () => {
    api.rerender('6ed2e539', render)
  })

}

script.__file = "resources/js/Jetstream/ApplicationMark.vue"

export default script