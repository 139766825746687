<template>
    <div>
        <div class="p-6 sm:px-20 bg-white border-b border-gray-200">
            <div class="mt-4">
                <jet-application-logo class="block h-12 w-auto" />
            </div>

            <div class="mt-4 text-2xl">
                Administration
            </div>

            <div class="mt-6 text-gray-500">
                <!-- TODO Add text? -->
            </div>
        </div>

        <div class="bg-gray-200 bg-opacity-25 grid grid-cols-1 md:grid-cols-2">
            <div class="p-6 border-b-0 border-gray-200 md:border-t-0 md:border-r md:border-b">
                <div class="flex items-center">
                    <font-awesome-icon :icon="buildingIcon" class="w-8 h-8 text-gray-400"></font-awesome-icon>
                    <div class="ml-4 text-lg text-gray-600 leading-7 font-semibold"><inertia-link :href="route('companies.list')">{{ companiesCount + (companiesCount !== 1 ? ' Companies' : ' Company')  }} </inertia-link></div>
                </div>

                <div class="ml-12">
                    <div class="mt-2 text-sm text-gray-500">
                        Manage companies, managers, associated surveys and settings.
                    </div>

                    <inertia-link :href="route('companies.list')">
                        <div class="mt-3 flex items-center text-sm font-semibold text-lil-pulse">
                            <div>View all companies</div>

                            <div class="ml-1 text-lil-pulse">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                        </div>
                    </inertia-link>

                </div>
            </div>

            <div class="p-6 border-t border-b-0 border-gray-200 md:border-t-0 md:border-b">
                <div class="flex items-center">
                    <font-awesome-icon :icon="statsIcon" class="w-8 h-8 text-gray-400"></font-awesome-icon>
                    <div class="ml-4 text-lg text-gray-600 leading-7 font-semibold"><inertia-link :href="route('stats')">Statistics</inertia-link></div>
                </div>

                <div class="ml-12">
                    <div class="mt-2 text-sm text-gray-500">
                        Export results data from companies and teams.
                    </div>

                    <inertia-link :href="route('stats')">
                        <div class="mt-3 flex items-center text-sm font-semibold text-lil-pulse">
                            <div>View all statistics</div>

                            <div class="ml-1 text-lil-pulse">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                        </div>
                    </inertia-link>
                </div>
            </div>

            <div class="p-6 border-t border-gray-200 md:border-t-0 md:border-r">
                <div class="flex items-center">
                    <font-awesome-icon :icon="safetyPulseIcon" class="w-8 h-8 text-gray-400"></font-awesome-icon>
                    <div class="ml-4 text-lg text-gray-600 leading-7 font-semibold"><inertia-link :href="route('safety-pulse.index')">SafetyPulse</inertia-link></div>
                </div>

                <div class="ml-12">
                    <div class="mt-2 text-sm text-gray-500">
                        Send SafetyPulse notifications to users.
                    </div>

                    <inertia-link :href="route('safety-pulse.index')">
                        <div class="mt-3 flex items-center text-sm font-semibold text-lil-pulse">
                            <div>Go to SafetyPulse page</div>

                            <div class="ml-1 text-lil-pulse">
                                <svg viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </div>
                        </div>
                    </inertia-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBuilding, faChartBar, faBell } from '@fortawesome/free-regular-svg-icons'

    export default {
        props: [
            'companiesCount'
        ],

        components: {
            JetApplicationLogo,
            FontAwesomeIcon
        },

        data() {
            return {
                buildingIcon: faBuilding,
                statsIcon: faChartBar,
                safetyPulseIcon: faBell,
            }
        },
    }
</script>
