import { render } from "./AuthenticationCard.vue?vue&type=template&id=0ffdd8dc"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0ffdd8dc"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0ffdd8dc', script)) {
    api.reload('0ffdd8dc', script)
  }
  
  module.hot.accept("./AuthenticationCard.vue?vue&type=template&id=0ffdd8dc", () => {
    api.rerender('0ffdd8dc', render)
  })

}

script.__file = "resources/js/Jetstream/AuthenticationCard.vue"

export default script