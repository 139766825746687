<template>
    <app-layout>
        <template #header>
            <div class="flex flex-row">
                <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-8">
                    Companies
                </h2>

                <a href="#add-company" class="font-semibold text-xl text-gray-400 mr-8">
                    Add
                </a>

                <a href="#list-companies" class="font-semibold text-xl text-gray-400 mr-8">
                    View all
                </a>

            </div>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">

                <companies-manager class="mt-10 sm:mt-0"
                            :companies="companies"/>
            </div>
        </div>
    </app-layout>
</template>

<script>
    import CompaniesManager from './CompaniesManager'
    import AppLayout from '@/Layouts/AppLayout'

    export default {
        props: [
            'companies',
        ],

        components: {
            AppLayout,
            CompaniesManager,
        },
    }
</script>
