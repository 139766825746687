<template>
    <div>
        <div>
            <!-- Add Team Member -->
            <div class="anchor -mt-nav pt-nav" id="list-managers"></div>
            <jet-form-section @submitted="addMember">
                <template #title>
                    Add Manager
                </template>

                <template #description>
                    Add a new manager for this company.

                    <input type="file" class="hidden"
                           ref="importInput"
                           @change="submitImport">


                    <jet-secondary-button class="mt-6" type="button" @click.prevent="selectFile" :class="{ 'opacity-25': importForm.processing }" :disabled="importForm.processing">
                        Import from CSV
                    </jet-secondary-button>

                    <jet-action-message :on="importForm.recentlySuccessful" class="mr-3">
                        Import Done.
                    </jet-action-message>

                    <jet-action-message :on="importForm.hasErrors" class="mr-3 text-red-500">
                        Import error: <span v-html="importForm.hasErrors ? Object.values(importForm.errors).join('<br>') : ''"></span>
                    </jet-action-message>

                </template>

                <template #form>

                    <!-- Company Label -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="name" value="Name" />
                        <jet-input id="name" type="text" class="mt-1 block w-full" v-model="addMemberForm.name" />
                        <jet-input-error :message="addMemberForm.errors.name" class="mt-2" />
                    </div>

                    <!-- Company Name -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="email" value="Email" />
                        <jet-input id="email" type="email" class="mt-1 block w-full" v-model="addMemberForm.email" />
                        <jet-input-error :message="addMemberForm.errors.email" class="mt-2" />
                    </div>
                </template>

                <template #actions>
                    <jet-action-message :on="addMemberForm.recentlySuccessful" class="mr-3">
                        Manager Added.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': addMemberForm.processing }" :disabled="addMemberForm.processing">
                        Add
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <div v-if="company.managers.length > 0 || company.company_invitations.length > 0">
            <jet-section-border />

            <!-- Manage Team Members -->
            <div class="anchor " id="list-companies"></div>

            <div class="flex items-center space-x-2 content-end justify-end mb-4">
                <jet-label for="search" value="Search" class="mt-1 font-bold" />
                <jet-input id="search" type="search" class="text-xs form-input" v-model="filter" />
            </div>

            <jet-action-section class="mt-10 sm:mt-0">
                <template #title>
                    Managers
                </template>

                <template #description>
                    All of the company managers.
                </template>

                <!-- Manager List -->
                <template #content>
                    <div class="space-y-6">

                        <div class="flex items-center justify-between" v-for="manager in filteredManagers" :key="manager.id">
                            <div class="flex items-center">
                                <span>{{ manager.name }}</span><span class="ml-4">{{ manager.email }}</span>
                            </div>

                            <div class="justify-self-end">
                                <div v-if="manager.type ==='companyinvitation'" class="ml-4 text-gray-500">Invited</div>
                                <div v-if="manager.type ==='user'" class="ml-4 text-green-500">Accepted</div>
                            </div>

                            <div class="justify-self-end">
                                <!-- Manage Team members -->
                                <inertia-link
                                    v-if="manager.type ==='user'"
                                    :href="route('companies.manager.team', [company, manager])"
                                    as="button"
                                    type="button"
                                    class="ml-2 text-sm text-gray-400 underline">
                                    Edit
                                </inertia-link>
                            </div>
                            <div class="justify-self-end">
                                <!-- Delete company -->
                                <button v-if="manager.type ==='companyinvitation'" class="cursor-pointer ml-6 text-sm text-red-500"
                                        @click="confirmManagerRemoval(manager)">
                                    Cancel Invite
                                </button>

                                <button v-else-if="manager.type ==='user'" class="cursor-pointer ml-6 text-sm text-red-500"
                                        @click="confirmManagerRemoval(manager)">
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </jet-action-section>
        </div>

        <!-- Remove Team Member Confirmation Modal -->
        <jet-confirmation-modal :show="managerBeingRemoved" @close="managerBeingRemoved = null">
            <template #title>
                Remove Manager
            </template>

            <template #content>
                Are you sure you would like to remove this manager?
            </template>

            <template #footer>
                <jet-secondary-button @click="managerBeingRemoved = null">
                    Cancel
                </jet-secondary-button>

                <jet-danger-button class="ml-2" @click="removeManager" :class="{ 'opacity-25': removeManagerForm.processing }" :disabled="removeManagerForm.processing">
                    Remove
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage'
    import JetActionSection from '@/Jetstream/ActionSection'
    import JetButton from '@/Jetstream/Button'
    import JetConfirmationModal from '@/Jetstream/ConfirmationModal'
    import JetDangerButton from '@/Jetstream/DangerButton'
    import JetDialogModal from '@/Jetstream/DialogModal'
    import JetFormSection from '@/Jetstream/FormSection'
    import JetInput from '@/Jetstream/Input'
    import JetInputError from '@/Jetstream/InputError'
    import JetLabel from '@/Jetstream/Label'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton'
    import JetSectionBorder from '@/Jetstream/SectionBorder'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBuilding } from '@fortawesome/free-regular-svg-icons'

    export default {
        components: {
            FontAwesomeIcon,
            JetActionMessage,
            JetActionSection,
            JetButton,
            JetConfirmationModal,
            JetDangerButton,
            JetDialogModal,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetSectionBorder,
        },

        props: [
            'company'
        ],

        data() {
            return {
                addMemberForm: this.$inertia.form({
                    name: '',
                    email: ''
                }),

                importForm: this.$inertia.form({
                    file: null
                }),

                removeManagerForm: this.$inertia.form(),
                managerBeingRemoved: null,

                filter: ''
            }
        },
        computed: {
            filteredManagers() {
                return this.company.managers.concat(this.company.company_invitations).filter(
                    item => item.name.toLowerCase().includes(this.filter.toLowerCase()) || item.email.toLowerCase().includes(this.filter.toLowerCase()))
            }
        },
        methods: {
            selectFile() {
                this.importForm.reset();
                this.$refs.importInput.click();
            },

            submitImport() {
                if (this.$refs.importInput) {
                    this.importForm.file = this.$refs.importInput.files[0]
                }

                this.importForm.post(route('companies.manager.import', [this.company]), {
                    errorBag: 'importManagers',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.importForm.reset();
                    }
                });
            },

            addMember() {

                this.addMemberForm.post(route('companies.manager.store', [this.company]), {
                    errorBag: 'addCompanyManager',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.addMemberForm.reset();
                        this.logoPreview = null;
                    }
                });
            },

            confirmManagerRemoval(company) {
                this.managerBeingRemoved = company
            },

            removeManager() {

                this.removeManagerForm.delete(
                    route(
                        this.managerBeingRemoved.type === "user" ? 'companies.manager.delete' : 'company-invitations.destroy',
                        [this.company, this.managerBeingRemoved]
                    ), {
                    errorBag: 'removeManager',
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => (this.managerBeingRemoved = null),
                })
            },
        },
    }
</script>
