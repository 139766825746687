import { render } from "./CompaniesManager.vue?vue&type=template&id=4f5bfee2"
import script from "./CompaniesManager.vue?vue&type=script&lang=js"
export * from "./CompaniesManager.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f5bfee2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f5bfee2', script)) {
    api.reload('4f5bfee2', script)
  }
  
  module.hot.accept("./CompaniesManager.vue?vue&type=template&id=4f5bfee2", () => {
    api.rerender('4f5bfee2', render)
  })

}

script.__file = "resources/js/Pages/Companies/CompaniesManager.vue"

export default script