import { render } from "./List.vue?vue&type=template&id=5139b15b"
import script from "./List.vue?vue&type=script&lang=js"
export * from "./List.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5139b15b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5139b15b', script)) {
    api.reload('5139b15b', script)
  }
  
  module.hot.accept("./List.vue?vue&type=template&id=5139b15b", () => {
    api.rerender('5139b15b', render)
  })

}

script.__file = "resources/js/Pages/Companies/List.vue"

export default script