import { render } from "./ValidationErrors.vue?vue&type=template&id=0118f178"
import script from "./ValidationErrors.vue?vue&type=script&lang=js"
export * from "./ValidationErrors.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0118f178"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0118f178', script)) {
    api.reload('0118f178', script)
  }
  
  module.hot.accept("./ValidationErrors.vue?vue&type=template&id=0118f178", () => {
    api.rerender('0118f178', render)
  })

}

script.__file = "resources/js/Jetstream/ValidationErrors.vue"

export default script