import { render } from "./DialogModal.vue?vue&type=template&id=5179157e"
import script from "./DialogModal.vue?vue&type=script&lang=js"
export * from "./DialogModal.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5179157e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5179157e', script)) {
    api.reload('5179157e', script)
  }
  
  module.hot.accept("./DialogModal.vue?vue&type=template&id=5179157e", () => {
    api.rerender('5179157e', render)
  })

}

script.__file = "resources/js/Jetstream/DialogModal.vue"

export default script