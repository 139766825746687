<template>
    <div>
        <div class="anchor -mt-nav pt-nav" id="update-company"></div>
        <jet-form-section @submitted="updateCompany">
            <template #title>
                Company information
            </template>

            <template #description>
                Update the company's name and information.
            </template>

            <template #form>

                <div class="col-span-6 sm:col-span-4">
                    <!-- Profile Logo File Input -->
                    <input type="file" class="hidden"
                           ref="logo"
                           @change="updateLogoPreview">

                    <jet-label for="logo" value="Logo" />

                    <!-- Current Profile Photo -->
                    <div class="mt-2" v-show="!logoPreview && company.logo_url">
                        <img :src="company.logo_url" :alt="company.name" class="h-20 w-20 object-cover">
                    </div>

                    <!-- New Profile Logo Preview -->
                    <div class="mt-2" v-if="logoPreview">
                            <span class="block w-20 h-20"
                                  :style="'background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + logoPreview + '\');'">
                            </span>
                    </div>

                    <jet-secondary-button class="mt-2 mr-2" type="button" @click.prevent="selectNewLogo">
                        Select A New Logo
                    </jet-secondary-button>

                    <jet-secondary-button type="button" class="mt-2" @click.prevent="deleteLogoPreview" v-if="logoPreview">
                        {{ company.logo_url ? 'Cancel' : 'Remove Logo' }}
                    </jet-secondary-button>

                    <jet-danger-button type="button" class="mt-2" @click.prevent="deleteLogo" v-else-if="company.logo_url">
                        Delete Logo
                    </jet-danger-button>


                    <jet-input-error :message="form.errors.logo" class="mt-2" />
                </div>

                <!-- Company Label -->
                <div class="col-span-6 sm:col-span-4">
                    <jet-label for="company-label" value="Label" />

                    <jet-autocomplete-input list-id="company-label"
                                            :list="labels"
                                            class="mt-1 block w-full"
                                            v-model="form.label"
                    />

                    <jet-input-error :message="form.errors.label" class="mt-2" />
                </div>

                <!-- Company Name -->
                <div class="col-span-6 sm:col-span-4">
                    <jet-label for="company-name" value="Company Name" />

                    <jet-input id="company-name"
                               type="text"
                               class="mt-1 block w-full"
                               v-model="form.name"
                    />

                    <jet-input-error :message="form.errors.name" class="mt-2" />
                </div>

                <div class="col-span-6 sm:col-span-4">
                    <jet-label for="lang" value="Language" />
                    <jet-select id="lang" class="mt-1 block w-full" v-model="form.lang" :options="langOptions"/>
                    <jet-input-error :message="form.errors.lang" class="mt-2" />
                </div>
            </template>

            <template #actions>
                <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                    Saved.
                </jet-action-message>

                <jet-button :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Save
                </jet-button>
            </template>
        </jet-form-section>
    </div>

</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage'
    import JetConfirmationModal from '@/Jetstream/ConfirmationModal'
    import JetButton from '@/Jetstream/Button'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton'
    import JetDangerButton from '@/Jetstream/DangerButton'
    import JetFormSection from '@/Jetstream/FormSection'
    import JetInput from '@/Jetstream/Input'
    import JetAutocompleteInput from '@/Jetstream/AutocompleteInput'
    import JetInputError from '@/Jetstream/InputError'
    import JetLabel from '@/Jetstream/Label'
    import JetSelect from '@/Jetstream/Select'

    export default {
        components: {
            JetActionMessage,
            JetConfirmationModal,
            JetButton,
            JetDangerButton,
            JetSecondaryButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetAutocompleteInput,
            JetSelect
        },

        props: ['company', 'labels'],

        data() {
            return {
                form: this.$inertia.form({
                    name: this.company.name,
                    label: this.company.label,
                    logo: null,
                    lang: this.company.lang
                }),

                logoPreview: null,

                langOptions: [
                    { value: 'en', label: "English"},
                    { value: 'fr', label: "Français"},
                ]
            }
        },

        methods: {
            updateCompany() {
                if (this.$refs.logo && this.logoPreview) {
                    this.form.logo = this.$refs.logo.files[0]
                }

                this.form.post(route('companies.update', {
                    company: this.company.id
                }), {
                    errorBag: 'updateCompany',
                    preserveScroll: true
                });
            },

            selectNewLogo() {
                this.$refs.logo.click();
            },

            updateLogoPreview() {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.logoPreview = e.target.result;
                };

                if (this.$refs.logo.files[0] !== null) {
                    reader.readAsDataURL(this.$refs.logo.files[0]);
                }
            },

            deleteLogoPreview() {
                this.logoPreview = null
            },

            deleteLogo() {
                this.$inertia.delete(route('companies.deleteLogo', {
                    company: this.company.id
                }), {
                    preserveScroll: true,
                    onSuccess: () => (this.logoPreview = null),
                });
            },
        },
    }
</script>
