import { render } from "./UpdatePasswordForm.vue?vue&type=template&id=56f235d2"
import script from "./UpdatePasswordForm.vue?vue&type=script&lang=js"
export * from "./UpdatePasswordForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "56f235d2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('56f235d2', script)) {
    api.reload('56f235d2', script)
  }
  
  module.hot.accept("./UpdatePasswordForm.vue?vue&type=template&id=56f235d2", () => {
    api.rerender('56f235d2', render)
  })

}

script.__file = "resources/js/Pages/Profile/UpdatePasswordForm.vue"

export default script