import { render } from "./CompanyList.vue?vue&type=template&id=6f5fc152"
import script from "./CompanyList.vue?vue&type=script&lang=js"
export * from "./CompanyList.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6f5fc152"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6f5fc152', script)) {
    api.reload('6f5fc152', script)
  }
  
  module.hot.accept("./CompanyList.vue?vue&type=template&id=6f5fc152", () => {
    api.rerender('6f5fc152', render)
  })

}

script.__file = "resources/js/Pages/Stats/CompanyList.vue"

export default script