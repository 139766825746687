<template>
    <div>
        <div>
            <!-- Add Team Member -->
            <div class="anchor -mt-nav pt-nav" id="add-company"></div>
            <jet-form-section @submitted="addCompany">
                <template #title>
                    Add Company
                </template>

                <template #description>
                    Add a new company.
                </template>

                <template #form>
                    <div class="col-span-6 sm:col-span-4">
                        <!-- Profile Logo File Input -->
                        <input type="file" class="hidden"
                               ref="logo"
                               @change="updateLogoPreview">

                        <jet-label for="logo" value="Logo" />

                        <!-- New Profile Logo Preview -->
                        <div class="mt-2" v-if="logoPreview">
                            <span class="block w-20 h-20"
                                  :style="'background-size: cover; background-repeat: no-repeat; background-position: center center; background-image: url(\'' + logoPreview + '\');'">
                            </span>
                        </div>

                        <jet-secondary-button class="mt-2 mr-2" type="button" @click.prevent="selectNewLogo">
                            Select A New Logo
                        </jet-secondary-button>

                        <jet-secondary-button type="button" class="mt-2" @click.prevent="deleteLogo" v-if="logoPreview">
                            Remove Logo
                        </jet-secondary-button>

                        <jet-input-error :message="addCompanyForm.errors.logo" class="mt-2" />
                    </div>

                    <div class="col-span-6">
                        <div class="max-w-xl text-sm text-gray-600">
                            Please provide a label and name for the new company.
                        </div>
                    </div>

                    <!-- Company Label -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="company-label" value="Label" />

                        <jet-autocomplete-input list-id="company-label"
                                                :list="labels"
                                                class="mt-1 block w-full"
                                                v-model="addCompanyForm.label"
                        />

                        <jet-input-error :message="addCompanyForm.errors.label" class="mt-2" />
                    </div>

                    <!-- Company Name -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="company-name" value="Company name" />
                        <jet-input id="company-name" type="text" class="mt-1 block w-full" v-model="addCompanyForm.name" />
                        <jet-input-error :message="addCompanyForm.errors.name" class="mt-2" />
                    </div>

                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="company-lang" value="Language" />
                        <jet-select id="company-lang" class="mt-1 block w-full" v-model="addCompanyForm.lang" :options="langOptions"/>
                        <jet-input-error :message="addCompanyForm.errors.lang" class="mt-2" />
                    </div>
                </template>

                <template #actions>
                    <jet-action-message :on="addCompanyForm.recentlySuccessful" class="mr-3">
                        Company Added.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': addCompanyForm.processing }" :disabled="addCompanyForm.processing">
                        Add
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <div v-if="companies.length > 0">
            <jet-section-border />

            <!-- Manage Team Members -->
            <div class="anchor " id="list-companies"></div>

            <div class="flex items-center space-x-2 content-end justify-end mb-4">
                <jet-label for="search" value="Search" class="mt-1 font-bold" />
                <jet-input id="search" type="search" class="text-xs form-input" v-model="filter" />
            </div>

            <jet-action-section class="mt-10 sm:mt-0">
                <template #title>
                    Companies
                </template>

                <template #description>
                    All of the companies using Lil-Pulse.
                </template>

                <!-- Companies List -->
                <template #content>
                    <div class="space-y-6">
                        <div class="grid grid-cols-3 items-center justify-between"
                             v-for="company in filteredCompanies" :key="company.id"
                        >
                            <div class="flex items-center" :class="{ 'opacity-40': company.deleted_at }">
                                <img v-if="company.logo_url" class="object-cover w-8 h-8" :src="company.logo_url" :alt="company.name">
                                <font-awesome-icon v-else  :icon="defaultIcon" class="w-8 h-8"></font-awesome-icon>
                                <div class="ml-4">{{ company.label }} - {{ company.name }}</div>
                            </div>

                            <div
                                v-if="company.deleted_at"
                                class="items-center justify-self-end text-sm text-gray-500">
                                Disabled
                            </div>
                            <div v-else></div>


                            <div class="items-center justify-self-end">
                                <!-- Manage Company members and survey -->
                                <inertia-link
                                    v-if="!company.deleted_at"
                                    :href="route('companies.show', company)"
                                    as="button"
                                    type="button"
                                    class="ml-2 text-sm text-gray-400 underline">
                                    Edit
                                </inertia-link>

                                <!-- Disable company -->
                                <button
                                    v-if="!company.deleted_at"
                                    class="cursor-pointer ml-6 text-sm text-red-500"
                                    @click="confirmCompanyRemoval(company)">
                                    Disable
                                </button>

                                <!-- Enable company -->
                                <button
                                    v-else
                                    class="cursor-pointer ml-6 text-sm text-green-500"
                                    @click="confirmCompanyEnabling(company)">
                                    Enable
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </jet-action-section>
        </div>

        <!-- Disable company Confirmation Modal -->
        <jet-confirmation-modal :show="companyBeingRemoved" @close="companyBeingRemoved = null">
            <template #title>
                Disable Company
            </template>

            <template #content>
                Are you sure you would like to disable this company?
            </template>

            <template #footer>
                <jet-secondary-button @click="companyBeingRemoved = null">
                    Cancel
                </jet-secondary-button>

                <jet-danger-button class="ml-2" @click="removeCompany" :class="{ 'opacity-25': removeCompanyForm.processing }" :disabled="removeCompanyForm.processing">
                    Disable
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>

        <!-- Enable Confirmation Modal -->
        <jet-confirmation-modal :show="companyBeingEnabled" @close="companyBeingEnabled = null" :icon="questionIcon">
            <template #title>
                Enable Company
            </template>

            <template #content>
                Are you sure you would like to enable this company?
            </template>

            <template #footer>
                <jet-secondary-button @click="companyBeingEnabled = null">
                    Cancel
                </jet-secondary-button>

                <jet-button class="ml-2" @click="enableCompany" :class="{ 'opacity-25': enableCompanyForm.processing }" :disabled="enableCompanyForm.processing">
                    Enable
                </jet-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage'
    import JetActionSection from '@/Jetstream/ActionSection'
    import JetButton from '@/Jetstream/Button'
    import JetConfirmationModal from '@/Jetstream/ConfirmationModal'
    import JetDangerButton from '@/Jetstream/DangerButton'
    import JetDialogModal from '@/Jetstream/DialogModal'
    import JetFormSection from '@/Jetstream/FormSection'
    import JetInput from '@/Jetstream/Input'
    import JetInputError from '@/Jetstream/InputError'
    import JetLabel from '@/Jetstream/Label'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton'
    import JetSectionBorder from '@/Jetstream/SectionBorder'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBuilding } from '@fortawesome/free-regular-svg-icons'
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
    import JetAutocompleteInput from '@/Jetstream/AutocompleteInput'
    import JetSelect from '@/Jetstream/Select'

    export default {
        components: {
            FontAwesomeIcon,
            JetActionMessage,
            JetActionSection,
            JetButton,
            JetConfirmationModal,
            JetDangerButton,
            JetDialogModal,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSecondaryButton,
            JetSectionBorder,
            JetAutocompleteInput,
            JetSelect
        },

        props: [
            'companies'
        ],

        data() {
            return {
                addCompanyForm: this.$inertia.form({
                    name: '',
                    label: '',
                    logo: null,
                    lang: ''
                }),

                removeCompanyForm: this.$inertia.form(),
                enableCompanyForm: this.$inertia.form(),

                companyBeingRemoved: null,
                companyBeingEnabled: null,

                logoPreview: null,
                defaultIcon: faBuilding,
                questionIcon: faQuestionCircle,

                filter: '',

                langOptions: [
                    { value: 'en', label: "English"},
                    { value: 'fr', label: "Français"},
                ]
            }
        },
        computed: {
            labels() {
                return [...new Set(this.companies.map((company) => company.label))];
            },
            filteredCompanies() {
                return this.companies.filter(
                    item => item.name.toLowerCase().includes(this.filter.toLowerCase()) || item.label.toLowerCase().includes(this.filter.toLowerCase())
                ).sort(
                    (item1, item2) =>
                        item1.deleted_at && item2.deleted_at ? (item2.deleted_at > item1.deleted_at ? 1 : -1)
                            : (item1.deleted_at ? 1 : (item2.deleted_at ? -1 : (
                                (item1.label + ' - ' + item1.name) > (item2.label + ' - ' + item2.name) ? 1 : -1
                            )))
                )
            }
        },
        methods: {
            addCompany() {
                if (this.$refs.logo && this.logoPreview) {
                    this.addCompanyForm.logo = this.$refs.logo.files[0]
                }

                this.addCompanyForm.post(route('companies.store'), {
                    errorBag: 'createCompany',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.addCompanyForm.reset();
                        this.logoPreview = null;
                    }
                });
            },

            confirmCompanyRemoval(company) {
                this.companyBeingRemoved = company
            },

            confirmCompanyEnabling(company) {
                this.companyBeingEnabled = company
            },

            removeCompany() {
                this.removeCompanyForm.delete(route('companies.disable', [this.companyBeingRemoved]), {
                    errorBag: 'removeCompany',
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => (this.companyBeingRemoved = null),
                })
            },

            enableCompany() {
                this.enableCompanyForm.put(route('companies.enable', [this.companyBeingEnabled]), {
                    errorBag: 'enableCompany',
                    preserveScroll: true,
                    preserveState: true,
                    onSuccess: () => (this.companyBeingEnabled = null),
                })
            },

            selectNewLogo() {
                this.$refs.logo.click();
            },

            updateLogoPreview() {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.logoPreview = e.target.result;
                };

                if (this.$refs.logo.files[0] !== null) {
                    reader.readAsDataURL(this.$refs.logo.files[0]);
                }
            },

            deleteLogo() {
                this.logoPreview = null
            },
        },
    }
</script>
