import { render } from "./ApplicationLogo.vue?vue&type=template&id=4e07f5d2"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4e07f5d2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4e07f5d2', script)) {
    api.reload('4e07f5d2', script)
  }
  
  module.hot.accept("./ApplicationLogo.vue?vue&type=template&id=4e07f5d2", () => {
    api.rerender('4e07f5d2', render)
  })

}

script.__file = "resources/js/Jetstream/ApplicationLogo.vue"

export default script