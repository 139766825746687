import { render } from "./DropdownLink.vue?vue&type=template&id=1114e65f"
import script from "./DropdownLink.vue?vue&type=script&lang=js"
export * from "./DropdownLink.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1114e65f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1114e65f', script)) {
    api.reload('1114e65f', script)
  }
  
  module.hot.accept("./DropdownLink.vue?vue&type=template&id=1114e65f", () => {
    api.rerender('1114e65f', render)
  })

}

script.__file = "resources/js/Jetstream/DropdownLink.vue"

export default script