<template>
    <div>
        <div>
            <!-- Add Team Member -->
            <div class="anchor -mt-nav pt-nav" id="add-safety-pulse"></div>
            <jet-form-section @submitted="sendSafetyPulse">
                <template #title>
                    Send SafetyPulse
                </template>

                <template #description>
                    Send SafetyPulse to a user or list of users.
                </template>

                <template #form>
                    <div class="col-span-6">
                        <div class="max-w-xl text-sm text-gray-600">
                            List of UUIDs (comma separated list)
                        </div>
                    </div>

                    <!-- Company Name -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="safety-pulse-users" value="UUIDs" />
                        <jet-input id="safety-pulse-users" type="text" class="mt-1 block w-full" v-model="safetyPulseForm.uuids" />
                        <jet-input-error :message="safetyPulseForm.errors.uuids" class="mt-2" />
                    </div>
                </template>

                <template #actions>
                    <jet-action-message :on="safetyPulseForm.recentlySuccessful" class="mr-3">
                        SafetyPulse Sent.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': safetyPulseForm.processing }" :disabled="safetyPulseForm.processing">
                        Send
                    </jet-button>
                </template>
            </jet-form-section>
        </div>
    </div>
</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage'
    import JetActionSection from '@/Jetstream/ActionSection'
    import JetButton from '@/Jetstream/Button'
    import JetFormSection from '@/Jetstream/FormSection'
    import JetInput from '@/Jetstream/Input'
    import JetInputError from '@/Jetstream/InputError'
    import JetLabel from '@/Jetstream/Label'
    import JetSectionBorder from '@/Jetstream/SectionBorder'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import JetAutocompleteInput from '@/Jetstream/AutocompleteInput'
    import JetSelect from '@/Jetstream/Select'

    export default {
        components: {
            FontAwesomeIcon,
            JetActionMessage,
            JetActionSection,
            JetButton,
            JetFormSection,
            JetInput,
            JetInputError,
            JetLabel,
            JetSectionBorder,
            JetAutocompleteInput,
            JetSelect
        },

        props: [

        ],

        data() {
            return {
                safetyPulseForm: this.$inertia.form({
                    uuids: '',
                }),
            }
        },
        computed: {

        },
        methods: {
            sendSafetyPulse() {
                this.safetyPulseForm.post(route('safety-pulse.notify'), {
                    errorBag: 'sendSafetyPulse',
                    preserveScroll: true,
                    onSuccess: () => {
                        this.safetyPulseForm.reset();
                    }
                });
            },
        },
    }
</script>
