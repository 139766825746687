<template>
    <input v-bind="$attrs" class="border-gray-300 focus:border-lil-pulse focus:ring focus:ring-lil-pulse focus:ring-opacity-50 rounded-md shadow-sm"
           :value="modelValue"
           type="text"
           @input="$emit('update:modelValue', $event.target.value)"
           :list="list ? listId + '-dataList' : null"
           ref="input">
    <datalist v-if="list" :id="listId + '-dataList'">
        <option v-for="item in list" :value="item"></option>
    </datalist>
</template>

<script>
    export default {
        props: {
            modelValue: {
                type: String
            },
            list: {
                type: Array,
                default: null
            },
            listId : {
                type: String
            }
        },

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus()
            }
        }
    }
</script>

