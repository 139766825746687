<template>
    <app-layout>
        <template #header>
            <div class="flex flex-row">
                <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-8">
                    Statistics
                </h2>
            </div>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">

                <div class="mb-6">

                    <div class="mt-5 md:mt-0 md:col-span-2">
                        <div class="px-4 py-5 bg-white sm:p-6 shadow sm:rounded-tl-md sm:rounded-tr-md">
                            <h3 class="font-semibold text-lg text-gray-800 leading-tight mb-6">{{ company.name }}</h3>

                            <div>
                                <ul class="divide-y divide-gray-300">
                                    <li v-for="manager of company.managers" class="p-4 hover:bg-gray-50">

                                        <div class="grid grid-cols-6">
                                            <div class="inline-flex items-center col-span-3">{{manager.name }}</div>
                                            <div class="inline-flex items-center col-span-2 text-sm">{{ manager.owned_team.nb_surveys }} Weekly surveys</div>

                                            <div  class="inline-flex justify-end">
                                                <jet-secondary-button type="button" @click="downloadTeamData(manager.owned_team)">
                                                    Export Data
                                                </jet-secondary-button>

                                                <jet-secondary-button class="ml-2" type="button" @click="downloadTeamUsersData(manager.owned_team)">
                                                    Export by User Data
                                                </jet-secondary-button>
                                            </div>
                                        </div>
                                        <div v-if="manager.owned_team.nb_surveys > 0" class="mt-4">
                                            <!-- <div class="text-sm font-semibold">Results from {{ formatted(company.results.start_date) }} to {{ formatted(company.results.end_date) }}</div>-->
                                            <ul class="divide-y divide-gray-200">
                                                <li v-for="question of manager.owned_team.results.questions" class="p-2 flex gap-2">
                                                    <div class="flex-initial text-gray-500">{{ question.label }}</div>

                                                    <div class="flex-1">{{ question.average }} <small>average</small></div>
                                                    <div class="flex-1" v-for="answer of question.answers_by_months">
                                                        {{ answer.value }} <small>{{ monthlyFormatted(answer) }}</small>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="flex items-center justify-end px-4 py-3 bg-gray-50 text-right sm:px-6 shadow sm:rounded-bl-md sm:rounded-br-md">
                            <jet-button type="button" @click="downloadCompanyData()">
                                Export all Data
                            </jet-button>
                            <jet-button type="button" class="ml-2" @click="downloadCompanyUsersData()">
                                Export all by User Data
                            </jet-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </app-layout>
</template>

<script>

    import AppLayout from '@/Layouts/AppLayout'
    import JetSectionTitle from '@/Jetstream/SectionTitle'
    import JetSecondaryButton from '@/Jetstream/SecondaryButton'
    import JetButton from '@/Jetstream/Button'
    import moment from "moment";

    export default {
        props: [
            'company'
        ],

        components: {
            AppLayout,
            JetSectionTitle,
            JetSecondaryButton,
            JetButton
        },

        methods: {
            formatted(date) {
                return moment(date).format("LL")
            },
            monthlyFormatted(answer) {
                return moment(answer.year + ' ' + answer.month, 'Y M').format("MMMM, YYYY")
            },
            downloadTeamData(team) {
                window.open(route('stats.team.download', [this.company, team]), '_blank')
            },
            downloadTeamUsersData(team) {
                window.open(route('stats.team.download_by_user', [this.company, team]), '_blank')
            },
            downloadCompanyData() {
                window.open(route('stats.company.download', [this.company]), '_blank')
            },
            downloadCompanyUsersData() {
                window.open(route('stats.company.download_by_user', [this.company]), '_blank')
            }
        }
    }
</script>
