<template>
    <select class="border-gray-300 focus:border-lil-pulse focus:ring focus:ring-lil-pulse focus:ring-opacity-50 rounded-md shadow-sm"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            ref="input">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>

<script>
    export default {
        props: ['modelValue', 'options'],

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus()
            }
        }
    }
</script>

