import { render } from "./SecondaryButton.vue?vue&type=template&id=8dd9837c"
import script from "./SecondaryButton.vue?vue&type=script&lang=js"
export * from "./SecondaryButton.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8dd9837c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8dd9837c', script)) {
    api.reload('8dd9837c', script)
  }
  
  module.hot.accept("./SecondaryButton.vue?vue&type=template&id=8dd9837c", () => {
    api.rerender('8dd9837c', render)
  })

}

script.__file = "resources/js/Jetstream/SecondaryButton.vue"

export default script