import { render } from "./ResponsiveNavLink.vue?vue&type=template&id=c1e95d36"
import script from "./ResponsiveNavLink.vue?vue&type=script&lang=js"
export * from "./ResponsiveNavLink.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "c1e95d36"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('c1e95d36', script)) {
    api.reload('c1e95d36', script)
  }
  
  module.hot.accept("./ResponsiveNavLink.vue?vue&type=template&id=c1e95d36", () => {
    api.rerender('c1e95d36', render)
  })

}

script.__file = "resources/js/Jetstream/ResponsiveNavLink.vue"

export default script