import { render } from "./TermsOfService.vue?vue&type=template&id=63d45180"
import script from "./TermsOfService.vue?vue&type=script&lang=js"
export * from "./TermsOfService.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "63d45180"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('63d45180', script)) {
    api.reload('63d45180', script)
  }
  
  module.hot.accept("./TermsOfService.vue?vue&type=template&id=63d45180", () => {
    api.rerender('63d45180', render)
  })

}

script.__file = "resources/js/Pages/TermsOfService.vue"

export default script