import { render } from "./FormSection.vue?vue&type=template&id=68c2427d"
import script from "./FormSection.vue?vue&type=script&lang=js"
export * from "./FormSection.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "68c2427d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('68c2427d', script)) {
    api.reload('68c2427d', script)
  }
  
  module.hot.accept("./FormSection.vue?vue&type=template&id=68c2427d", () => {
    api.rerender('68c2427d', render)
  })

}

script.__file = "resources/js/Jetstream/FormSection.vue"

export default script