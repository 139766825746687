import { render } from "./TwoFactorAuthenticationForm.vue?vue&type=template&id=6b3f2958"
import script from "./TwoFactorAuthenticationForm.vue?vue&type=script&lang=js"
export * from "./TwoFactorAuthenticationForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6b3f2958"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6b3f2958', script)) {
    api.reload('6b3f2958', script)
  }
  
  module.hot.accept("./TwoFactorAuthenticationForm.vue?vue&type=template&id=6b3f2958", () => {
    api.rerender('6b3f2958', render)
  })

}

script.__file = "resources/js/Pages/Profile/TwoFactorAuthenticationForm.vue"

export default script