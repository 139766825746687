import { render } from "./SectionBorder.vue?vue&type=template&id=2661c926"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2661c926"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2661c926', script)) {
    api.reload('2661c926', script)
  }
  
  module.hot.accept("./SectionBorder.vue?vue&type=template&id=2661c926", () => {
    api.rerender('2661c926', render)
  })

}

script.__file = "resources/js/Jetstream/SectionBorder.vue"

export default script