import { render } from "./ConfirmsPassword.vue?vue&type=template&id=2befd4a2"
import script from "./ConfirmsPassword.vue?vue&type=script&lang=js"
export * from "./ConfirmsPassword.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2befd4a2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2befd4a2', script)) {
    api.reload('2befd4a2', script)
  }
  
  module.hot.accept("./ConfirmsPassword.vue?vue&type=template&id=2befd4a2", () => {
    api.rerender('2befd4a2', render)
  })

}

script.__file = "resources/js/Jetstream/ConfirmsPassword.vue"

export default script