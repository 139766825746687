import { render } from "./SafetyPulseForm.vue?vue&type=template&id=25331c6a"
import script from "./SafetyPulseForm.vue?vue&type=script&lang=js"
export * from "./SafetyPulseForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "25331c6a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('25331c6a', script)) {
    api.reload('25331c6a', script)
  }
  
  module.hot.accept("./SafetyPulseForm.vue?vue&type=template&id=25331c6a", () => {
    api.rerender('25331c6a', render)
  })

}

script.__file = "resources/js/Pages/SafetyPulse/SafetyPulseForm.vue"

export default script