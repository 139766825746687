<template>
    <app-layout>
        <template #header>
            <div class="flex flex-row">
                <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-8">
                    <div class="flex items-center">
                        <img v-if="company.logo_url" class="object-cover w-6 h-6" :src="company.logo_url" :alt="company.name">
                        <font-awesome-icon v-else  :icon="defaultIcon" class="w-6 h-6"></font-awesome-icon>
                        <inertia-link
                            :href="route('companies.show', company)"
                            class="ml-4">
                            {{ company.label }} - {{ company.name }}
                        </inertia-link>
                    </div>
                </h2>

                <a href="#team-members" class="font-semibold text-xl text-gray-400 mr-8">
                    Team Settings - {{ team.manager.name }}
                </a>
            </div>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">

                <team-member-manager class="mt-10 sm:mt-0"
                            :team="team"/>

            </div>
        </div>
    </app-layout>
</template>

<script>
    import TeamMemberManager from './TeamMemberManager'
    import AppLayout from '@/Layouts/AppLayout'
    import JetSectionBorder from '@/Jetstream/SectionBorder'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import {faBuilding} from '@fortawesome/free-regular-svg-icons'

    export default {
        props: [
            'team',
            'company'
        ],

        components: {
            AppLayout,
            JetSectionBorder,
            TeamMemberManager,
            FontAwesomeIcon
        },

        data() {
            return {
                defaultIcon: faBuilding
            }
        }
    }
</script>
