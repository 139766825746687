<template>
    <app-layout>
        <template #header>
            <div class="flex flex-row">
                <h2 class="font-semibold text-xl text-gray-800 leading-tight mr-8">
                    <div class="flex items-center">
                        <img v-if="company.logo_url" class="object-cover w-6 h-6" :src="company.logo_url" :alt="company.name">
                        <font-awesome-icon v-else  :icon="defaultIcon" class="w-6 h-6"></font-awesome-icon>
                        <div class="ml-4">{{ company.label }} - {{ company.name }}</div>
                    </div>
                </h2>
                <a href="#list-managers" class="font-semibold text-xl text-gray-400 mr-8">
                    Managers
                </a>
                <a href="#survey" class="font-semibold text-xl text-gray-400 mr-8">
                    Survey
                </a>
                <a href="#update-company" class="font-semibold text-xl text-gray-400 mr-8">
                    Settings
                </a>
            </div>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
                <company-members-manager class="mt-10 sm:mt-0" :company="company" />

                <jet-section-border />
                <survey-manager class="mt-10 sm:mt-0" :company="company" />

                <jet-section-border />

                <update-company-form :company="company" :labels="labels" />
            </div>
        </div>
    </app-layout>
</template>

<script>
    import AppLayout from '@/Layouts/AppLayout'
    import JetSectionBorder from '@/Jetstream/SectionBorder'
    import UpdateCompanyForm from '@/Pages/Companies/UpdateCompanyForm'
    import CompanyMembersManager from '@/Pages/Companies/CompanyMembersManager'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faBuilding } from '@fortawesome/free-regular-svg-icons'
    import SurveyManager from "@/Pages/Surveys/SurveyManager";

    export default {
        props: [
            'company', 'labels'
        ],

        components: {
            AppLayout,
            JetSectionBorder,
            UpdateCompanyForm,
            CompanyMembersManager,
            SurveyManager,
            FontAwesomeIcon
        },

        data() {
            return {
                defaultIcon: faBuilding
            }
        }
    }
</script>
