import { render } from "./TwoFactorChallenge.vue?vue&type=template&id=96fe0a94"
import script from "./TwoFactorChallenge.vue?vue&type=script&lang=js"
export * from "./TwoFactorChallenge.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "96fe0a94"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('96fe0a94', script)) {
    api.reload('96fe0a94', script)
  }
  
  module.hot.accept("./TwoFactorChallenge.vue?vue&type=template&id=96fe0a94", () => {
    api.rerender('96fe0a94', render)
  })

}

script.__file = "resources/js/Pages/Auth/TwoFactorChallenge.vue"

export default script