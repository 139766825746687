import { render } from "./Show.vue?vue&type=template&id=348d746c"
import script from "./Show.vue?vue&type=script&lang=js"
export * from "./Show.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "348d746c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('348d746c', script)) {
    api.reload('348d746c', script)
  }
  
  module.hot.accept("./Show.vue?vue&type=template&id=348d746c", () => {
    api.rerender('348d746c', render)
  })

}

script.__file = "resources/js/Pages/Profile/Show.vue"

export default script