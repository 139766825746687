import { render } from "./DeleteUserForm.vue?vue&type=template&id=4ed1f029"
import script from "./DeleteUserForm.vue?vue&type=script&lang=js"
export * from "./DeleteUserForm.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4ed1f029"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4ed1f029', script)) {
    api.reload('4ed1f029', script)
  }
  
  module.hot.accept("./DeleteUserForm.vue?vue&type=template&id=4ed1f029", () => {
    api.rerender('4ed1f029', render)
  })

}

script.__file = "resources/js/Pages/Profile/DeleteUserForm.vue"

export default script