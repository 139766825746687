import { render } from "./AuthenticationCardLogo.vue?vue&type=template&id=5c457327"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5c457327"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5c457327', script)) {
    api.reload('5c457327', script)
  }
  
  module.hot.accept("./AuthenticationCardLogo.vue?vue&type=template&id=5c457327", () => {
    api.rerender('5c457327', render)
  })

}

script.__file = "resources/js/Jetstream/AuthenticationCardLogo.vue"

export default script