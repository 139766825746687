<template>
    <div>

        <div>
            <!-- Update Survey periodicity -->
            <div class="anchor -mt-nav pt-nav" id="add-question"></div>
            <jet-form-section @submitted="addQuestion">
                <template #title>
                    Add Question
                </template>

                <template #description>
                    Add a new question to the company survey.
                </template>

                <template #form>
                    <!-- Section -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="section" value="Category" />
                        <jet-input id="section" type="text" class="mt-1 block w-full" v-model="questionForm.section" />
                        <jet-input-error :message="questionForm.errors.section" class="mt-2" />
                    </div>
                    <!-- Label -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="question-label" value="Question" />
                        <jet-input id="question-label" type="text" class="mt-1 block w-full" v-model="questionForm.label" />
                        <jet-input-error :message="questionForm.errors.label" class="mt-2" />
                    </div>
                    <!-- Type -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="type" value="Response type" />
                        <jet-select id="type" class="mt-1 block w-full" v-model="questionForm.type" :options="typeOptions"/>
                        <jet-input-error :message="questionForm.errors.type" class="mt-2" />
                    </div>
                </template>

                <template #actions>
                    <jet-action-message :on="questionForm.recentlySuccessful" class="mr-3">
                        Question Added.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': questionForm.processing }" :disabled="questionForm.processing">
                        Add
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <div>
            <jet-section-border />

            <!-- Manage Survey questions -->
            <div class="anchor -mt-nav pt-nav" id="survey"></div>

            <jet-form-section @submitted="updateQuestions" class="mt-10 sm:mt-0" :full-width="true">
                <template #title>
                    Survey
                </template>

                <template #description>
                    Manage company survey questions.
                </template>

                <!-- Companies List -->
                <template #form>
                    <draggable v-if="updatedQuestionsForm.questions.length > 0"
                               v-model="updatedQuestionsForm.questions"
                               group="questions" item-key="id"
                               chosen-class="chosen-class"
                               drag-class="drag-class"
                               ghost-class="drag-class"
                               handle=".handle"
                               tag="transition-group"
                               class="divide-y-2 divide-gray-200"
                               :component-data="{
                                  tag: 'div',
                                  type: 'transition-group',
                                  name: !drag ? 'flip-list' : null
                                }"
                               v-bind="dragOptions">
                        <template #item="{element}">
                            <div class="flex items-center justify-between py-4">
                                <div class="flex items-center cursor-move handle">
                                    <div class="ml-4">
                                        <font-awesome-icon :icon="barsIcon" class="w-6 h-6 text-gray-600"></font-awesome-icon>
                                    </div>
                                </div>
                                <div class="flex-auto flex-col space-y-2">
                                    <div class="flex-auto items-center ml-4 grid grid-cols-5">
                                        <jet-label value="Category" />
                                        <jet-input class="ml-4 block w-full col-span-4" v-model="element.section" type="text"/>
                                    </div>

                                    <div class="flex-auto items-center ml-4 grid grid-cols-5">
                                        <jet-label value="Question" />
                                        <jet-input class="ml-4 block w-full col-span-4" v-model="element.label" type="text"/>
                                    </div>

                                    <div class="flex-auto items-center ml-4 grid grid-cols-5">
                                        <jet-label value="Type" />
                                        <jet-select class="ml-4 block col-span-4 w-1/2" v-model="element.type" :options="typeOptions"/>
                                    </div>
                                </div>

                                <div class="items-center justify-self-end">
                                    <button
                                        class="cursor-pointer ml-6 text-sm text-red-500"
                                        @click.prevent="confirmQuestionRemoval(element)">
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </template>
                    </draggable>

                    <div v-else>No questions defined.</div>
                </template>

                <template #actions>
                    <jet-action-message :on="updatedQuestionsForm.recentlySuccessful" class="mr-3">
                        Questions Updated.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': updatedQuestionsForm.processing }" :disabled="updatedQuestionsForm.processing">
                        Update
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <div>
            <jet-section-border />

            <!-- Update Survey periodicity -->
            <div class="anchor -mt-nav pt-nav" id="update-survey"></div>
            <jet-form-section @submitted="updateSurvey">
                <template #title>
                    Periodicity
                </template>

                <template #description>
                    Define when the survey is sent to the users for this question.
                </template>

                <template #form>
                    <!-- Periodicity - week modulo -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="week_modulo" value="Week day" />
                        <jet-select name="week_modulo" v-model="surveyForm.week_modulo" class="mt-1 block w-full" :options="moduloOptions"/>
                        <jet-input-error :message="surveyForm.errors.week_modulo" class="mt-2" />
                    </div>

                    <!-- Periodicity - weekday -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="week_day" value="Week day" />
                        <jet-select name="week_day" v-model="surveyForm.week_day" class="mt-1 block w-full" :options="dayOptions"/>
                        <jet-input-error :message="surveyForm.errors.week_day" class="mt-2" />
                    </div>

                    <!-- Periodicity hour -->
                    <div class="col-span-6 sm:col-span-4">
                        <jet-label for="hour" value="Hour" />
                        <jet-input id="hour" type="time" class="mt-1 block w-full" v-model="surveyForm.hour" />
                        <jet-input-error :message="surveyForm.errors.hour" class="mt-2" />
                    </div>
                </template>

                <template #actions>
                    <jet-action-message :on="surveyForm.recentlySuccessful" class="mr-3">
                        Periodicity Updated.
                    </jet-action-message>

                    <jet-button :class="{ 'opacity-25': surveyForm.processing }" :disabled="surveyForm.processing">
                        Update
                    </jet-button>
                </template>
            </jet-form-section>
        </div>

        <!-- Disable question Confirmation Modal -->
        <jet-confirmation-modal :show="questionBeingRemoved" @close="questionBeingRemoved = null">
            <template #title>
                Remove question
            </template>

            <template #content>
                Are you sure you would like to remove this question?
            </template>

            <template #footer>
                <jet-secondary-button @click="questionBeingRemoved = null">
                    Cancel
                </jet-secondary-button>

                <jet-danger-button class="ml-2" @click="removeQuestion" :class="{ 'opacity-25': removeQuestionForm.processing }" :disabled="removeQuestionForm.processing">
                    Remove
                </jet-danger-button>
            </template>
        </jet-confirmation-modal>
    </div>
</template>

<script>
import JetActionMessage from '@/Jetstream/ActionMessage'
import JetActionSection from '@/Jetstream/ActionSection'
import JetButton from '@/Jetstream/Button'
import JetConfirmationModal from '@/Jetstream/ConfirmationModal'
import JetDangerButton from '@/Jetstream/DangerButton'
import JetDialogModal from '@/Jetstream/DialogModal'
import JetFormSection from '@/Jetstream/FormSection'
import JetInput from '@/Jetstream/Input'
import JetInputError from '@/Jetstream/InputError'
import JetLabel from '@/Jetstream/Label'
import JetSelect from '@/Jetstream/Select'
import JetSecondaryButton from '@/Jetstream/SecondaryButton'
import JetSectionBorder from '@/Jetstream/SectionBorder'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import draggable from 'vuedraggable'

import moment from 'moment'

export default {
    components: {
        FontAwesomeIcon,
        JetActionMessage,
        JetActionSection,
        JetButton,
        JetConfirmationModal,
        JetDangerButton,
        JetDialogModal,
        JetFormSection,
        JetInput,
        JetSelect,
        JetInputError,
        JetLabel,
        JetSecondaryButton,
        JetSectionBorder,
        draggable
    },

    props: [
        'company'
    ],

    data() {
        return {
            surveyForm: this.$inertia.form({
                week_modulo: this.company.survey.week_modulo,
                week_day: this.company.survey.week_day,
                hour: moment(this.company.survey.hour, "HH:mm:ss").format('HH:mm'),
            }),

            questionForm: this.$inertia.form({
                section: '',
                label: '',
                type: ''
            }),

            updatedQuestionsForm: this.$inertia.form({
                questions: this.company.survey.questions
            }),

            removeQuestionForm: this.$inertia.form(),
            questionBeingRemoved: null,

            logoPreview: null,
            defaultIcon: faBuilding,
            questionIcon: faQuestionCircle,
            barsIcon: faBars,

            filter: '',
            drag: false,

            moduloOptions: [
                { value: 1, label: "Weekly"},
                { value: 2, label: "Biweekly"},
                { value: 3, label: "Every 3 weeks"},
                { value: 4, label: "Every 4 weeks"},
            ],
            dayOptions: [
                { value: 1, label: "Mondays"},
                { value: 2, label: "Tuesdays"},
                { value: 3, label: "Wednesdays"},
                { value: 4, label: "Thursdays"},
                { value: 5, label: "Fridays"},
                { value: 6, label: "Saturdays"},
                { value: 7, label: "Sundays"}
            ],

            typeOptions: [
                { value: 'range', label: "Range"},
                { value: 'text', label: "Text"},
            ]
        }
    },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost-class"
                };
            }
        },
        methods: {
            updateSurvey() {
                this.surveyForm.post(route('companies.survey.update', [this.company]), {
                    errorBag: 'updateSurvey',
                    preserveScroll: true,
                    onSuccess: (data) => {

                    }
                });
            },

            updateQuestions() {
                this.updatedQuestionsForm.post(route('companies.survey.update_questions', [this.company]), {
                    errorBag: 'updateQuestions',
                    preserveScroll: true,
                    onSuccess: (data) => {
                        this.questionForm.reset();
                    }
                });
            },

            addQuestion() {
                this.questionForm.post(route('companies.survey.add_question', [this.company]), {
                    errorBag: 'addQuestion',
                    preserveScroll: true,
                    onSuccess: (data) => {
                        this.questionForm.reset();
                        this.updatedQuestionsForm.questions = this.company.survey.questions
                    }
                });
            },

            confirmQuestionRemoval(question) {
                this.questionBeingRemoved = question
            },

            removeQuestion() {
                this.updatedQuestionsForm.questions = this.updatedQuestionsForm.questions.filter(item => item.id != this.questionBeingRemoved.id)
                this.questionBeingRemoved = null;
            },
        },
    }
</script>
