import { render } from "./PrivacyPolicy.vue?vue&type=template&id=41527301"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=js"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "41527301"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41527301', script)) {
    api.reload('41527301', script)
  }
  
  module.hot.accept("./PrivacyPolicy.vue?vue&type=template&id=41527301", () => {
    api.rerender('41527301', render)
  })

}

script.__file = "resources/js/Pages/PrivacyPolicy.vue"

export default script