<template>
    <inertia-link :href="'/'">
        <svg xmlns="http://www.w3.org/2000/svg" width="179.926" height="59.698" viewBox="0 0 89.963 59.698">
            <g id="Groupe_246" data-name="Groupe 246" transform="translate(-234.121 -340.157)">
                <g id="Groupe_242" data-name="Groupe 242" transform="translate(234.121 340.157)">
                    <path id="Tracé_197" data-name="Tracé 197" d="M254.675,382.374h-2.531A18.076,18.076,0,0,0,234.121,400.4h0a18.075,18.075,0,0,0,18.022,18.023h46.2c.188,0,.373-.009.56-.014s.372.014.56.014h0A20.275,20.275,0,0,0,319.677,398.2h0a20.276,20.276,0,0,0-20.216-20.216H297.9" transform="translate(-234.121 -363.265)" fill="#fbba16"/>
                    <path id="Tracé_198" data-name="Tracé 198" d="M326.006,366.379h0a22.476,22.476,0,0,0-22.409-22.41h0a22.476,22.476,0,0,0-22.41,22.41" transform="translate(-260.818 -343.969)" fill="#fbba16"/>
                </g>
                <g id="Groupe_243" data-name="Groupe 243" transform="translate(236.364 342.538)">
                    <path id="Tracé_199" data-name="Tracé 199" d="M305.726,419.152c-.144,0-.286,0-.428-.009l-.131,0-.109,0c-.151.005-.3.009-.451.009h-46.2a19.1,19.1,0,1,1,0-38.209h2.531v2.164h-2.531a16.941,16.941,0,1,0,0,33.881h46.2c.127,0,.253,0,.379-.008l.18-.006.2.006c.119,0,.237.008.357.008a19.134,19.134,0,1,0,0-38.268h-.068l-.046,0h-1.443v-2.164h1.557a21.3,21.3,0,1,1,0,42.6Z" transform="translate(-239.303 -361.834)" fill="#141d3e"/>
                    <path id="Tracé_200" data-name="Tracé 200" d="M333.353,366.029h-2.164a21.328,21.328,0,1,0-42.655,0H286.37a23.491,23.491,0,1,1,46.983,0Z" transform="translate(-266.001 -342.538)" fill="#141d3e"/>
                </g>
                <g id="Groupe_244" data-name="Groupe 244" transform="translate(265.134 356.21)">
                    <rect id="Rectangle_199" data-name="Rectangle 199" width="23.714" height="18.828" rx="5.669" transform="translate(0 11.987)" fill="#e36492"/>
                    <path id="Tracé_201" data-name="Tracé 201" d="M320.269,393.05v-4.271a5.111,5.111,0,1,1,10.221,0v4.271h2.6v-4.271a7.715,7.715,0,1,0-15.431,0v4.271Z" transform="translate(-313.523 -381.063)" fill="#e36492"/>
                    <path id="Tracé_202" data-name="Tracé 202" d="M334.831,423.71a2.128,2.128,0,1,0-3.161,1.85v2.72a1.033,1.033,0,1,0,2.066,0v-2.72A2.119,2.119,0,0,0,334.831,423.71Z" transform="translate(-320.846 -404.047)" fill="#e36492"/>
                </g>
                <g id="Groupe_245" data-name="Groupe 245" transform="translate(267.18 357.155)">
                    <path id="Tracé_203" data-name="Tracé 203" d="M332.854,431.935H314.047a3.54,3.54,0,0,1-3.535-3.535V414.479a3.54,3.54,0,0,1,3.535-3.535h18.807a3.54,3.54,0,0,1,3.536,3.535V428.4A3.54,3.54,0,0,1,332.854,431.935Zm-18.807-18.828a1.373,1.373,0,0,0-1.371,1.371V428.4a1.373,1.373,0,0,0,1.371,1.371h18.807a1.373,1.373,0,0,0,1.372-1.371V414.479a1.373,1.373,0,0,0-1.372-1.371Z" transform="translate(-310.512 -398.957)" fill="#141d3e"/>
                    <path id="Tracé_204" data-name="Tracé 204" d="M337.677,397.4h-4.769v-5.353a4.028,4.028,0,0,0-8.057,0V397.4h-4.769v-5.354a8.8,8.8,0,0,1,17.595,0Zm-2.605-2.164h.441v-3.19a6.633,6.633,0,0,0-13.267,0v3.19h.441v-3.19a6.192,6.192,0,0,1,12.384,0Z" transform="translate(-315.94 -383.246)" fill="#141d3e"/>
                    <path id="Tracé_205" data-name="Tracé 205" d="M336.2,433.66a2.118,2.118,0,0,1-2.115-2.115v-2.166a3.21,3.21,0,1,1,4.23,0v2.166A2.118,2.118,0,0,1,336.2,433.66Zm0-7.731a1.048,1.048,0,0,0-1.046,1.046,1.031,1.031,0,0,0,.542.906l.5.283.5-.283a1.031,1.031,0,0,0,.542-.906A1.048,1.048,0,0,0,336.2,425.929Z" transform="translate(-323.263 -406.23)" fill="#141d3e"/>
                </g>
            </g>
        </svg>
    </inertia-link>
</template>
